<div class="bg">
  <div class="container">
    <div class="row">
      <div class="custom">
        <!-- Begin header -->

        <div class="col-md-12 text-center">
          <img src="../../../assets/images/logo/keettdelleke.png" alt="Syntax Logo" [routerLink]="['/']" style="cursor: pointer">
        </div>
        <div>
          <div class="col-md-6 text-center">
            <app-count-down class="header_back"></app-count-down>
          </div>
          <div class="col-md-6 text-center">
            <h1 *ngIf="open !== null" class="header_back">De balie is: <a style="color: green; pointer-events: none" *ngIf="this.open">Open</a><a style="color: red; pointer-events: none" *ngIf="!this.open">Gesloten</a></h1>
          </div>
        </div>
        <div class="content">
          <div class="col-md-12">
            &nbsp;
            <hr>
          </div>
        </div>
        <!-- Eind header -->

        <!-- Begin scoreboard -->
        <div class="col-md-12 zwbg">
          <div class="row">
            <div class="col-md-3 text-center">
              <h1>Balie status</h1>
              <button style="color: white" class="btn customKnop" (click)="this.main.updateBool('administratie', 'status', 'open', !this.open); this.succesSound();"><a *ngIf="open" style="text-decoration: none; color: white">Sluit balie</a><a *ngIf="!open" style="text-decoration: none; color: white">Open balie</a></button>
            </div>
            <div class="col-md-6 text-center">
              <h1>Groepen</h1>
              <h3>Achievements</h3>
              <div class="col-md-6">
                <button class="btn customKnop" (click)="koppelAchievement()">Wijs achievement aan</button>
              </div>
              <div class="col-md-6">
                <button class="btn customKnop" (click)="ontKoppelAchievement()">Ontneem achievement</button>
              </div>
            </div>
            <div class="col-md-3 text-center">
              <h1>Melding</h1>
              <button class="btn customKnop" (click)="maakMelding()">Maak melding</button><br>
              <button class="btn customKnop" (click)="verwijderMelding()">Verwijder melding</button>
            </div>
          </div>
          &nbsp;
          <hr>
          <div class="row">
            <div class="col-md-3 text-center">
              <h1>Nieuwe groep</h1>
              <button class="btn customKnop" (click)="createNewGroup()">Maak nieuwe groep</button>
            </div>
            <div class="col-md-6 text-center">
              <h1>Achievement</h1>
              <div class="col-md-6">
                <button class="btn customKnop" (click)="maakAchievement()">Maak achievement</button>
              </div>
              <div class="col-md-6">
                <button class="btn customKnop" (click)="verwijderAchievement()">Verwijder achievement</button>
              </div>
            </div>
            <div class="col-md-3 text-center">
              <h1>Verwijder groep</h1>
              <button class="btn customKnop" (click)="verwijderGroep()">Verwijder groep</button>
            </div>
          </div>
        </div>
        <!-- Einde scoreboard -->
      </div>
    </div>
  </div>
</div>
