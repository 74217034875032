<div class="row">
  <div class="col wd">
    <div style="text-align: center;">
      <h1>Login</h1>
    </div>
    <form #postForm="ngForm" (ngSubmit)="onFormSubmit(postForm.value)" class="text-left">
      <label for="naam">Email</label>
      <div class="forangular m-group mb3">
        <input type="text" [ngClass]="{'failure': !succes}" class="form-control" id="naam" required ngModel name="naam"/>
      </div>
      <label for="wachtwoord">Wachtwoord</label>
      <div class="form-group mb-3">
        <input type="password" [ngClass]="{'failure': !succes}" class="form-control" id="wachtwoord" required ngModel name="wachtwoord"/>
      </div>

      <div class="row clearfix das">
        <div class="col text-right float-right"><button type="submit" class="btn btn-lg btn-block login-active" [disabled]="!postForm.valid">Login</button></div>
      </div>
    </form>
  </div>
</div>

