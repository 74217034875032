<video playsinline autoplay muted loop id="myVideo">
  <source src="./assets/video/joostvideo.mp4" type="video/mp4">
</video>
<div class="jumbotron vertical-center" *ngIf="!login">
  <div class="container modal-login custom2">
    <div class="row">
      <div class="col">
        <div class="align-items-center custom">
          <app-login (login)="switchView()"></app-login>
        </div>
      </div>
    </div>
  </div>
</div>
<app-dashboard *ngIf="login"></app-dashboard>
