<video playsinline autoplay muted loop id="myVideo">
  <source src="./assets/video/joostvideo.mp4" type="video/mp4">
</video>
<div class="bg">
  <div class="container">
    <div class="row">
      <div class="custom">
        <!-- Begin header -->
        <div class="col-md-12 text-center">
          <img src="../../assets/images/logo/keettdelleke.png" alt="Keet logo" style="cursor: pointer">
        </div>
        <div>
          <div class="col-md-6 text-center">
            <app-count-down class="header_back"></app-count-down>
          </div>
          <div class="col-md-6 text-center">
            <h1 *ngIf="open !== null" class="header_back">De keet is: <a style="color: green; pointer-events: none" *ngIf="this.open">Open</a><a style="color: red; pointer-events: none" *ngIf="!this.open">Gesloten</a></h1>
          </div>
        </div>
        <div class="content">
          <div class="col-md-12">
            &nbsp;
            <hr>
          </div>
        </div>
        <!-- Eind header -->

        <!-- Begin scoreboard -->
        <div class="col-md-12 zwbg">
          <div class="header_back_board" *ngFor="let i of this.main.group | async; let index = index;" (click)="openLijst(index)">
            <div class="row equal" style="margin-left: 0">
              <div class="col-md-3 text-center">
                <h1 style="margin-top: 15px">{{i.payload.doc.id}}</h1>
              </div>
              <div class="col-md-3 text-center customScore">
                <h1 class="header_back_board3">Score: {{i.payload.doc.data().punten}}</h1>
              </div>
              <div class="col-md-6 text-right vcenter">
                <div class="col-md-12 vcenter">
                  <ng-container *ngFor="let j of this.main.achievement | async">
                    <img style="width: 10%;" *ngIf="i.payload.doc.data().achievements.includes(j.payload.doc.id)" src="{{j.payload.doc.data().icon}}" alt="" class="header_back_board2">
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="index === selectedIndex">
              <hr class="sb">
              <div class="equal">
                <ng-container *ngFor="let j of this.main.achievement | async">
                  <div class="col-md-6 text-center" [ngStyle]="{'filter': i.payload.doc.data().achievements.includes(j.payload.doc.id) ? 'opacity(0.5)' : 'grayscale(0)'}">
                    <div class="col-xs-6">
                      <img src="{{j.payload.doc.data().icon}}" alt="" class="header_back_board2">
                    </div>
                    <div class="col-xs-6">
                      <h1 class="header_back_board3" [ngStyle]="{'text-decoration': i.payload.doc.data().achievements.includes(j.payload.doc.id) ? 'line-through' : 'none'}">{{j.payload.doc.data().punten}}</h1>
                    </div>
                    <div class="col xs-12" style="padding: 40px 0 40px 0; margin: 20px 0 20px 0;">
                      <h1>{{j.payload.doc.data().naam}}</h1>
                      <p>{{j.payload.doc.data().beschrijving}}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <br>
          <hr>
          <div class="row">
            <div class="col-md-12 text-center">
              <h2>Made with love by <a href="https://kvdmr.nl">Koen van der Marel</a></h2>
            </div>
          </div>
          <br>
        </div>
        <!-- Einde scoreboard -->
      </div>
    </div>
  </div>
</div>
